import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  // getVisitsForCompany,
  getVisitBySearch,
} from "../utils/router";
import CustomTable from "../Components/CustomTable";
import CustomInput from "../Components/CustomInput";
import { sortVisits, tableKeyTitles } from "./utils";
import "./VisitsAtCompany.css";

const VisitsAtCompany = (props) => {
  const { apiKey } = props;

  const [visits, setVisits] = useState([]);
  const [masterIdFilter, setMasterIdFilter] = useState("");
  const [error, setError] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  // useEffect(() => {
  //   getVisitsForCompany(apiKey, setVisits).then((visits) => {
  //     if (visits?.error) {
  //       setError("Error fetching visits");
  //     }
  //   });
  // }, [apiKey, setVisits]);

  const handleMasterIdSearch = async () => {
    if (
      masterIdFilter.length &&
      !visits.some((visit) => visit.masterId === masterIdFilter)
    ) {
      setError("");
      setDataLoading(true);
      await getVisitBySearch(apiKey, masterIdFilter, setVisits, setError);
      setDataLoading(false);
    }
  };

  const handleMasterIdFilterChange = (e) => {
    setMasterIdFilter(e.target.value);
  };

  const handleEnterPress = async (e) => {
    if (e.keyCode === 13) {
      if (
        masterIdFilter.length &&
        !visits.some((visit) => visit.masterId === masterIdFilter)
      ) {
        setError("");
        setDataLoading(true);
        await getVisitBySearch(apiKey, masterIdFilter, setVisits, setError);
        setDataLoading(false);
      }
    }
  };

  const visitList = sortVisits(visits).filter((visit) => {
    if (!masterIdFilter) return true;
    return visit.masterId.includes(masterIdFilter);
  });

  const prettyDateAndTime = (date) => {
    const dated = new Date(date);
    const hours = dated.getHours().toString().padStart(2, "0");
    const minutes = dated.getMinutes().toString().padStart(2, "0");
    return `${
      dated.getMonth() + 1
    }-${dated.getDate()}-${dated.getFullYear()} ${hours}:${minutes}`;
  };

  const rowOverrides = {
    status: (value, visit) => {
      switch (value) {
        case "admin":
          if (visit.isClinicianAdmin) return "Clinical admin";
          if (visit.isTechSupport) return "Technical admin";
          return "Admin";
        case "pending":
          return "Pending patient";
        case "active":
          return "Active for doctor";
        case "holding":
          return "Holding";
        case "canceled":
          return "Canceled";
        case "resolved":
          return "Resolved";
        default:
          return value;
      }
    },
    outcome: (_, visit) => {
      if (visit.resolvedTimestamp) {
        if (!Object.keys(visit).includes("isRefer") || visit.isRefer) {
          return "Referred";
        } else {
          return "Prescribed";
        }
      } else if (visit.cancelTimestamp) {
        return "Canceled";
      } else {
        return "Open";
      }
    },
    createTimestamp: (date) => {
      return prettyDateAndTime(date);
    },
    updateTimestamp: (date) => {
      return prettyDateAndTime(date);
    },
    resolvedTimestamp: (date) => {
      if (!!date) {
        return prettyDateAndTime(date);
      }
    },
  };

  useEffect(() => {
    setMasterIdFilter("");
  }, [visits]);

  return (
    <div className="visits-page">
      <div>
        <CustomInput
          type="text"
          name="masterId"
          placeholder="Search by MasterID"
          onChange={handleMasterIdFilterChange}
          onKeyUp={handleEnterPress}
        />
        <button onClick={handleMasterIdSearch} className="searchButton">
          Search
        </button>
      </div>
      {error === "" ? (
        dataLoading ? (
          <div className="error-container">
            <h1>Loading...</h1>
          </div>
        ) : (
          <>
            {/* <CustomInput
                type="text"
                name="masterId"
                placeholder="Search by MasterID"
                onChange={handleMasterIdFilterChange}
              /> */}
            <div className="visits-page">
              <CustomTable
                list={visitList}
                getRowKey={(element) => element._id}
                tableKeyTitles={tableKeyTitles}
                rowOverrides={rowOverrides}
                tableType={"visits"}
              />
            </div>
          </>
        )
      ) : (
        <div className="error-container">
          <h1>{error}</h1>
        </div>
      )}
    </div>
  );
};

VisitsAtCompany.propTypes = {
  apiKey: PropTypes.string,
};

export default VisitsAtCompany;
